@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-link {
  text-decoration: none !important;
  color: white !important;
}

.btn-link:hover {
  text-decoration: none !important;
  color: red !important;
}

.dropdown-item:hover {
  background-color: gray !important;
  color: white !important;
}

.text-image-float {
  float: left;
  margin-right: 1rem;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.mt-100 {
  margin-top: 100px !important;
}